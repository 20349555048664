import { FC, useEffect } from 'react'

import { useGetUserDetailsQuery } from '@store/services/user'
import { useUnleashContext } from '@unleash/proxy-client-react'

import { UnleashContextProps } from './UnleashContext.types'

const UnleashContext: FC<UnleashContextProps> = ({ isStartClient }) => {
  const { data: userDetailsData } = useGetUserDetailsQuery()
  const updateContext = useUnleashContext()

  const userId = userDetailsData?.id

  useEffect(() => {
    if (!isStartClient || userId == null) return
    updateContext({ userId: String(userId) })
  }, [userId, isStartClient])

  return null
}

export default UnleashContext
