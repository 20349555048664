import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import type {
  IUserDetails, IPartner, IUserDocument
} from '@testavivadk/common-tools/types'
import { authHeaderName } from '@testavivadk/common-tools/constants'
import { applicationUrl } from '@constants'

export const USER_API_REDUCER_KEY = 'userApi'
export const userApi = createApi({
  reducerPath: USER_API_REDUCER_KEY,
  baseQuery: baseQueryWithAuth({}),
  tagTypes: ['UserDetails'],
  endpoints: (builder) => ({
    getUserDetails: builder.query<IUserDetails, void>({
      query: () => ({
        url: `${applicationUrl}/api/user/details`,
        method: 'GET'
      }),
      providesTags: ['UserDetails']
    }),
    putUser: builder.mutation<unknown, Partial<IUserDetails>>({
      query: (userDetails) => ({
        url: `${applicationUrl}/api/user`,
        method: 'PUT',
        body: userDetails,
        responseHandler: 'text'
      }),
      invalidatesTags: ['UserDetails'],
      async onQueryStarted(updatedDetails, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUserDetails', undefined, (details) => {
            Object.assign(details, updatedDetails)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    putUnauthUser: builder.mutation<unknown, { formData: Partial<IUserDetails>, token: string }>({
      query: ({ formData, token }) => ({
        url: `${applicationUrl}/api/user`,
        method: 'PUT',
        body: { ...formData },
        responseHandler: 'text',
        headers: { [authHeaderName]: token }
      })
    }),
    postDeleteUser: builder.mutation<string, string>({
      query: (reason) => ({
        url: `${applicationUrl}/user/delete`,
        method: 'POST',
        responseHandler: 'text',
        body: { feedback: reason }
      })
    }),
    postChangePassword: builder.mutation<unknown, { password: string, newPassword: string, newPasswordRepeat: string }>({
      query: (data) => ({
        url: `${applicationUrl}/api/user/password-change`,
        method: 'PUT',
        body: data
      })
    }),
    postTogglePermissions: builder.mutation<void, { partnerId?: string, permission: string }>({
      query: (data) => ({
        url: `${applicationUrl}/api/user/permissions/toggle`,
        method: 'POST',
        body: data
      }),
      async onQueryStarted({ partnerId, permission }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUserDetails', undefined, (details) => {
            let updatedPermission
            if (partnerId == null) {
              updatedPermission = details?.internalPermissions.find((internalPermission) => internalPermission.key === permission)
            } else {
              updatedPermission = details?.partnerPermissions.find((internalPermission) => internalPermission.key === permission)
            }
            if (updatedPermission != null) {
              updatedPermission.accepted = !updatedPermission.accepted
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    getOtherUsers: builder.query<IUserDetails[], void>({
      query: () => ({
        url: `${applicationUrl}/api/user/other-users`,
        method: 'GET'
      })
    }),
    getMitIdAuthenticate: builder.query<string, string>({
      query: (redirectUrl) => ({
        url: `${applicationUrl}/mitid?redirecturl=${redirectUrl}`,
        method: 'GET',
        responseHandler: 'text'
      })
    }),
    getUserDocuments: builder.query<IUserDocument, string>({
      query: (documentsType) => ({
        url: `${applicationUrl}/api/user/documents/${documentsType}`,
        method: 'GET'
      })
    }),
    getResendActivationEmail: builder.query<string, { token: string, email: string }>({
      query: ({ token, email }) => ({
        url: `${applicationUrl}/api/user/resend-activation?token=${token}&email=${email}`,
        method: 'GET'
      })
    }),
    getActivateUser: builder.query<string, string>({
      query: (token) => ({
        url: `${applicationUrl}/api/users/authenticate/${token}`,
        method: 'GET',
        responseHandler: 'text'
      })
    }),
    postUserPartners: builder.mutation<void, IPartner['id']>({
      query: (partner) => ({
        url: `${applicationUrl}/api/user/save-partners`,
        method: 'POST',
        body: { partners: [partner] }
      })
    }),
    getConditions: builder.query<{ condition_html: string }, void>({
      query: () => ({
        url: '/api/conditions/current',
        method: 'GET'
      })
    }),
    postConditions: builder.mutation<string, void>({
      query: () => ({
        url: '/api/user/accept-conditions',
        method: 'POST',
        responseHandler: 'text'
      }),
      invalidatesTags: ['UserDetails']
    }),
    postAuthenticateUser: builder.mutation<string, { email: string, password: string }>({
      query: (data) => ({
        url: '/api/user/authenticate',
        method: 'POST',
        body: data
      })
    }),
    postReferenceSignUpUser: builder.mutation<string, {
      partner: IPartner['id']
      token: string
      isMarketingConsentsAccepted: boolean
      user: {
        email: string
        password: string,
        passwordRepeat: string
      }
      consents: string[]
      conditions: boolean
    }>({
      query: ({
        partner, token, isMarketingConsentsAccepted, user, consents, conditions
      }) => ({
        url: `/partner/signup/conditions/${partner}?token=${token}${isMarketingConsentsAccepted ? '&marketing=1' : ''}`,
        method: 'POST',
        responseHandler: 'text',
        body: { ...user, consents, conditions }
      })
    }),
    getPassworlessAuthentication: builder.query<void, string>({
      query: (token) => ({
        url: `${applicationUrl}/api/user/authenticate-magic-link/${token}`,
        method: 'GET',
        responseHandler: 'text',
        credentials: 'include'
      })
    })
  })
})

export const {
  useGetUserDetailsQuery,
  useLazyGetUserDetailsQuery,
  usePutUserMutation,
  usePostDeleteUserMutation,
  usePostChangePasswordMutation,
  usePostTogglePermissionsMutation,
  useGetOtherUsersQuery,
  useLazyGetMitIdAuthenticateQuery,
  useGetActivateUserQuery,
  useLazyGetActivateUserQuery,
  usePutUnauthUserMutation,
  useGetUserDocumentsQuery,
  useLazyGetResendActivationEmailQuery,
  useGetConditionsQuery,
  usePostUserPartnersMutation,
  useLazyGetUserDocumentsQuery,
  usePostAuthenticateUserMutation,
  usePostReferenceSignUpUserMutation,
  usePostConditionsMutation,
  useLazyGetPassworlessAuthenticationQuery
} = userApi
