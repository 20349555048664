import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'

import { useLocation } from 'react-router-dom'

import { unleashFlagKeys } from '@constants'
import { applicationSpaRoutes } from '@testavivadk/common-tools/constants'

export const useNewOverviewStatus = () => {
  const location = useLocation()
  const { flagsReady, flagsError } = useFlagsStatus()
  const isNewMyOverviewOn = useFlag(unleashFlagKeys.myOverviewPage)

  return {
    isEnabled: flagsReady && isNewMyOverviewOn && location.pathname === applicationSpaRoutes.myOverview,
    isLoading: !flagsReady && flagsError != null
  }
}
