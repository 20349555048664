import { StrictMode } from 'react'
import * as Sentry from "@sentry/react"
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'

import store from '@store/store'
import {
  createBrowserRouter, RouterProvider
} from 'react-router-dom'
import { AnalyticsProvider } from '@hooks/Analytics'
import MuiThemeProvider from '@components/MuiThemeProvider'
import UnleashProvider from '@components/UnleashProvider'

import ErrorBoundary from '@frontend-components/components/ErrorBoundary'
import LazyLoadRetry from '@components/LazyLoadRetry'

import PageRoutes from '@routes'

import '@frontend-components/css/global.scss'
import './i18n'

const { VITE_SENTRY_URL, VITE_IS_LOCAL_API_ENABLED } = import.meta.env

if (VITE_SENTRY_URL != null) {
  Sentry.init({
    dsn: VITE_SENTRY_URL,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      'Cannot read properties of undefined (reading \'consent\')',
      'window.CookieConsent is undefined',
      'undefined is not an object (evaluating \'window.CookieConsent.consent\')'
    ],
    replaysOnErrorSampleRate: 1.0
  })
}
(async () => {
  if (VITE_IS_LOCAL_API_ENABLED === 'true') {
    const { default: makeServer } = await import('@mocks/server')
    makeServer({ environment: 'development' })
  }
})()

const router = createBrowserRouter(PageRoutes)

const AppToRender = () => (
  <StrictMode>
    <ErrorBoundary fallbackComponent={LazyLoadRetry}>
      <Provider store={store}>
        <MuiThemeProvider>
          <UnleashProvider>
            <AnalyticsProvider>
              <RouterProvider router={router} />
            </AnalyticsProvider>
          </UnleashProvider>
        </MuiThemeProvider>
      </Provider>
    </ErrorBoundary>
  </StrictMode>
)

const domNode = document.getElementById('application-spa')
if (domNode != null) {
  const root = createRoot(domNode)
  root.render(<AppToRender />)
}
