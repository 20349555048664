import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { applicationUrl } from '@constants'
import { type IIdentificationFile } from '@testavivadk/common-tools/types'

export const IDENTIFICATION_FILES_NON_USER_API_KEY = 'identificationFilesNoneUserApi'
export const identificationFilesNonUserApi = createApi({
  reducerPath: IDENTIFICATION_FILES_NON_USER_API_KEY,
  baseQuery: fetchBaseQuery({ baseUrl: applicationUrl }),
  tagTypes: ['IDENTIFICATION_FILES'],
  endpoints: (builder) => ({
    getIdentificationFilesNonUser: builder.query<IIdentificationFile[], string>({
      query: (token) => ({
        url: `/api/identificationFile?upload_id_token=${token}`,
        method: 'GET'
      }),
      providesTags: ['IDENTIFICATION_FILES']
    }),
    postIdentificationFileNonUser: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: '/api/identificationFile',
        method: 'POST',
        body: formData,
        responseHandler: 'text'
      }),
      invalidatesTags: ['IDENTIFICATION_FILES']
    })
  })
})

export const {
  useGetIdentificationFilesNonUserQuery,
  usePostIdentificationFileNonUserMutation
} = identificationFilesNonUserApi
