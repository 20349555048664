import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AppState {
  isDrawerOpen: boolean
}

const initialState: AppState = {
  isDrawerOpen: true
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDrawerOpen(state, action: PayloadAction<AppState['isDrawerOpen']>) {
      state.isDrawerOpen = action.payload
    }
  }
})

export const { setDrawerOpen } = appSlice.actions
export default appSlice.reducer
