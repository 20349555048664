import { FC, PropsWithChildren } from 'react'

import { FlagProvider, IConfig } from '@unleash/proxy-client-react'
import UnleashContext from './components/UnleashContext'

const config: IConfig = {
  url: 'https://eu.app.unleash-hosted.com/eucc0001/api/frontend',
  clientKey: import.meta.env.VITE_UNLEASH_KEY ?? 'no-client-key',
  refreshInterval: 60 * 60 * 24, // poll once a day
  appName: 'ApplicationSPA'
}

export const isStartUnleashClient = import.meta.env.VITE_UNLEASH_KEY != null

const UnleashProvider: FC<PropsWithChildren> = ({ children }) => (
  <FlagProvider config={config} startClient={isStartUnleashClient}>
    <UnleashContext isStartClient={isStartUnleashClient} />
    {children}
  </FlagProvider>
)

export default UnleashProvider
