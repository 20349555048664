import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import type {
  IBasket, IBasketItem, TimeSlotType, ProductType, IUserDetails
} from '@testavivadk/common-tools/types'
import { PurchaseTypes } from '@testavivadk/common-tools/constants'
import {
  paymentUrl, applicationUrl, bookingUrl, authUserType
} from '@constants'

export const BASKET_API_REDUCER_KEY = 'basketApi'
export const basketApi = createApi({
  reducerPath: BASKET_API_REDUCER_KEY,
  tagTypes: ['BasketItems'],
  baseQuery: baseQueryWithAuth({}),
  endpoints: (builder) => ({
    getBasket: builder.query<IBasket, null>({
      query: () => ({
        url: `${applicationUrl}/api/basket`,
        method: 'GET'
      }),
      providesTags: ['BasketItems']
    }),
    getBasketById: builder.query<IBasket, IBasket['id']>({
      query: (id) => ({
        url: `${paymentUrl}/api/basket/${id}`,
        method: 'GET'
      }),
      providesTags: ['BasketItems']
    }),
    postBasketItem: builder.mutation<null, { product: ProductType, purchase_type: PurchaseTypes, partner?: string, identifier?: string }>({
      query: (body) => ({
        url: `${applicationUrl}/api/basket`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['BasketItems']
    }),
    putBasketItem: builder.mutation<null, Pick<IBasketItem, 'id' | 'purchase_type'>>({
      query: ({ id, purchase_type }) => ({
        url: `${applicationUrl}/api/basket/${id}`,
        method: 'PUT',
        body: { purchase_type }
      }),
      invalidatesTags: ['BasketItems']
    }),
    deleteBasketItem: builder.mutation({
      query: (id: IBasketItem['id']) => ({
        url: `${applicationUrl}/api/basket/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['BasketItems']
    }),
    getBasketPartnerText: builder.query<string, unknown>({
      query: () => ({
        url: `${applicationUrl}/api/basket/partner-text`,
        method: 'GET'
      })
    }),
    getTimeSlots: builder.query<TimeSlotType[], { from: string, to: string, basketId: IBasket['id'], userId: IUserDetails['id'] }>({
      query: ({
        from, to, basketId, userId
      }) => ({
        url: `${bookingUrl}/api/bookings/timeslot/availability?basket_id=${basketId}&from=${from}&to=${to}&user_id=${userId}&notice_type=internal&advisor_type=all`,
        method: 'GET',
        headers: { [authUserType]: 'user' }
      })
    })
  })
})

export const {
  useGetBasketQuery,
  useLazyGetBasketQuery,
  useLazyGetBasketByIdQuery,
  usePostBasketItemMutation,
  usePutBasketItemMutation,
  useDeleteBasketItemMutation,
  useGetBasketPartnerTextQuery,
  useLazyGetBasketPartnerTextQuery,
  useLazyGetTimeSlotsQuery,
  useGetTimeSlotsQuery
} = basketApi
