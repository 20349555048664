import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import { applicationUrl } from '@constants'
import { type INotification } from '@testavivadk/common-tools/types'

export const NOTIFICATIONS_API_REDUCER_KEY = 'notificationsApi'
export const notificationsApi = createApi({
  reducerPath: NOTIFICATIONS_API_REDUCER_KEY,
  baseQuery: baseQueryWithAuth({ baseUrl: applicationUrl }),
  endpoints: (builder) => ({
    getAllNotifications: builder.query<INotification[], void | null>({
      query: () => ({
        url: '/api/notifications',
        method: 'GET'
      })
    }),
    postMarkNotificationAsRead: builder.mutation<void, INotification['id']>({
      query: (id) => ({
        url: `/api/notifications/mark-as-read/${id}`,
        method: 'POST',
        responseHandler: 'text'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData('getAllNotifications', undefined, (notifications) => {
            const targetNotification = notifications.find((notification) => notification.id === id)

            if (targetNotification != null) {
              targetNotification.is_read = true
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    deleteNotification: builder.mutation<void, INotification['id']>({
      query: (id) => ({
        url: `/api/notifications/${id}`,
        method: 'DELETE',
        responseHandler: 'text'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData('getAllNotifications', undefined, (notifications) => notifications.filter((notification) => notification.id !== id))
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    notificationDispatch: builder.mutation<void, { id: INotification['id'], action: boolean } >({
      query: ({ id, action }) => ({
        url: `/api/notifications/${id}/dispatch`,
        method: 'POST',
        body: { action },
        responseHandler: 'text'
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData('getAllNotifications', undefined, (notifications) => notifications.filter((notification) => notification.id !== id))
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    })
  })
})

export const {
  useGetAllNotificationsQuery,
  usePostMarkNotificationAsReadMutation,
  useDeleteNotificationMutation,
  useNotificationDispatchMutation
} = notificationsApi
