import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SubmitActions, AuthenticationSteps } from '@testavivadk/features'
import { type IDocument } from '@types'
import { ProductType } from '@testavivadk/common-tools/types'

export interface ModalState {
  isPartnersModalOpen: boolean
  identityVerificationModalState: {
    isOpen: boolean,
    redirectUrl?: string
  }
  partnerModalIdentifier: string | null
  authenticationModal: {
    step?: AuthenticationSteps
    onSubmitCallback?: (response: Response, key: SubmitActions) => void
    isEmailVerificationRequired?: boolean
  } | null,
  downloadBlobData?: string
  documentRegistrationModalState: {
    isOpen: boolean,
    document?: IDocument
  }
  documentSignatureMethodSelectionModalState: {
    isOpen: boolean,
    document?: IDocument
  }
  lawdocsModalState: {
    isOpen: boolean
    content?: string
  }
  documentPaidTokenModalState: {
    isOpen: boolean,
    documentId?: IDocument['id']
    productType?: ProductType
  }
}

const initialState: ModalState = {
  isPartnersModalOpen: false,
  identityVerificationModalState: {
    isOpen: false,
    redirectUrl: undefined
  },
  partnerModalIdentifier: null,
  authenticationModal: null,
  downloadBlobData: undefined,
  documentRegistrationModalState: {
    isOpen: false,
    document: undefined
  },
  documentSignatureMethodSelectionModalState: {
    isOpen: false,
    document: undefined
  },
  lawdocsModalState: {
    isOpen: false,
    content: ''
  },
  documentPaidTokenModalState: {
    isOpen: false,
    documentId: undefined,
    productType: undefined
  }
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setPartnersModalOpen(state, action: PayloadAction<{ isOpen: boolean, identifier?: string }>) {
      state.isPartnersModalOpen = action.payload.isOpen
      if (action.payload.identifier == null) {
        state.partnerModalIdentifier = null
      } else {
        state.partnerModalIdentifier = action.payload.identifier
      }
    },
    setIdentityVerificationModalState(state, action: PayloadAction<ModalState['identityVerificationModalState']>) {
      state.identityVerificationModalState = { ...action.payload }
    },
    setAuthenticationModalData(state, action: PayloadAction<ModalState['authenticationModal']>) {
      state.authenticationModal = action.payload
    },
    setDownloadBlobModal(state, action: PayloadAction<string | undefined>) {
      state.downloadBlobData = action.payload
    },
    setDocumentRegistrationModalState(state, action: PayloadAction<ModalState['documentRegistrationModalState']>) {
      state.documentRegistrationModalState = action.payload
    },
    setDocumentSignatureMethodSelectionModalState(state, action: PayloadAction<ModalState['documentSignatureMethodSelectionModalState']>) {
      state.documentSignatureMethodSelectionModalState = action.payload
    },
    setLawdocsModalOpen(state, action: PayloadAction<ModalState['lawdocsModalState']>) {
      state.lawdocsModalState = { ...action.payload }
    },
    setDocumentPaidTokenModalState(state, action: PayloadAction<ModalState['documentPaidTokenModalState']>) {
      state.documentPaidTokenModalState = action.payload
    }
  }
})

export const {
  setPartnersModalOpen,
  setIdentityVerificationModalState,
  setAuthenticationModalData,
  setDownloadBlobModal,
  setDocumentRegistrationModalState,
  setDocumentSignatureMethodSelectionModalState,
  setLawdocsModalOpen,
  setDocumentPaidTokenModalState
} = modalSlice.actions
export default modalSlice.reducer
