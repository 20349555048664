import { FC, lazy, Suspense } from 'react'

import { useNewOverviewStatus } from '../../routes/MyOverview/MyOverview.hooks'

const LegacyLayout = lazy(() => import('./LegacyLayout'))
const NewLayout = lazy(() => import('./NewLayout'))

const AppLayout: FC = () => {
  const { isLoading, isEnabled } = useNewOverviewStatus()

  if (isLoading) return null
  if (isEnabled) return <Suspense><NewLayout /></Suspense>

  return <Suspense><LegacyLayout /></Suspense>
}

export default AppLayout
